


























import { Component, Ref } from 'vue-property-decorator'

import Confirmation from '@/components/modals/Confirmation.vue'
import DiscountForm from '@/components/forms/discounts/DiscountForm.vue'
import Tag from '@/components/_uikit/Tag.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import ManagerDiscountModule from '@/store/modules/manager/discounts'
import { SaleStatus, SaleResource, SaleStoreData } from '@/store/types'
import DictionaryModule from '@/store/modules/dictionary'


@Component({
  components: {
    Confirmation,
    DiscountForm,
    Tag,
  },
})
export default class SubjectDiscountItem extends NotifyMixin {
  @Ref() confirm!: Confirmation

  private isLoaded = false

  private get saleData(): SaleResource | null {
    return ManagerDiscountModule.discount
  }

  private get saleID () {
    return +this.$route.params.saleID
  }

  private get breadcrumbs () {
    return [{ name: 'Скидки на предметы', path: '/manager/commerce/discounts' }]
  }

  private get isDraw() {
    return this.saleData?.status === SaleStatus.DRAW
  }

  private get discountsStatus() {
    return DictionaryModule.discountsStatuses
  }

  private get discountsStatusValue() {
    const obj: Record<string | number, string> = {}
    this.discountsStatus.forEach(item => obj[item.value] = item.name.toLowerCase())
    return obj
  }

  private get discountsStatusColor() {
    return {
      [SaleStatus.DRAW]: 'grey',
      [SaleStatus.DELETED]: 'red',
      [SaleStatus.ACTIVE]: 'teal',
    }
  }

  private mounted() {
    if (!this.saleData || this.saleData.id !== this.saleID) {
      ManagerDiscountModule.fetchDiscount(this.saleID)
        .catch(err => {
          this.$router.replace({
            name: 'manager.commerce.discount.list',
          })
          this.notifyError(err)
        })
        .finally(() => this.isLoaded = true)
    } else {
      this.isLoaded = true
    }
  }

  private handleSubmit(payload: { form: SaleStoreData, publish: boolean }) {
    ManagerDiscountModule.saveDiscount({ data: payload.form, saleId: this.saleID })
      .then(() => {
        if (payload.publish) {
          this.publishDiscount()
        } else {
          this.notifySuccess('Скидка отредактирована')
          this.$router.push({
            name: 'manager.commerce.discount.list',
          })
        }
      })
      .catch(this.notifyError)
  }

  private handleDelete() {
    ManagerDiscountModule.deleteDiscount(this.saleID)
      .then(() => {
        this.notifyInfo('Скидка удалена')
        this.$router.replace({
          name: 'manager.commerce.discount.list',
        })
      })
      .catch(this.notifyError)
  }

  private confirmPublish() {
    this.confirm.open(
      'Публикация скидки',
      'Вы уверены? После публикации можно будет изменить только дату окончания скидки.',
      {
        buttonCancelText: 'Вернуться',
        buttonConfirmText: 'Да, сохранить',
        skin: 'primary',
      },
    )
      .then(() => {
        this.$bus.$emit('publishDiscount')
      })
      .catch(() => {return})
  }

  private publishDiscount() {
    ManagerDiscountModule.activatedDiscount(this.saleID)
      .then(() => {
        this.$router.push({
          name: 'manager.commerce.discount.list',
        })
      })
      .catch(this.notifyError)
  }
}
